
import Vue from 'vue'
import CaptionCard from "@/components/cards/CaptionCard.vue"
import ResponsiveWave from "../components/misc/ResponsiveWave.vue"
import SIO from '@/util/sio';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'ShareView',
  components: { CaptionCard, ResponsiveWave },
  computed: {
    ...mapGetters([
      'version'
    ]),
    
    
  },
  data: () => ({
    connected: false,
    caption: "",
    logo: "https://aihearu.com/imgs/logo.png",
    theme: {
      solid: {
        captionBGColor: "#1600FEFF",
        textColor: "#FFFFFF",
        name: "share",
      },
      light: {
        captionBGColor: "#00000040",
        textColor: "#FFFFFF",
        name: "obs",
      },
      dark: {
        captionBGColor: "#1600FEB0",
        textColor: "#FFFFFF",
        name: "obs",
      },
      transparent: {
        captionBGColor: "#00000000",
        textColor: "#FFFFFF",
        name: "obs",
      },
    },
    defaultTheme: "solid",
    sio: null as any,
    displayName: "Could not connect...",
    avatar: "",
    showVersion: false,
    token: "",
    title: "",
    obs: false,
    timeout: 5000,
    timer: 0
  }),
  mounted() {
    this.token = this.$route.params.code;
    this.obs = this.$route.params.obs ? true  : false;
    this.title = this.$route.query.title && this.$route.query.title.toString() || "";
    this.timeout = this.$route.query.timeout && parseInt(this.$route.query.timeout.toString()) || this.timeout;
    this.sio = SIO.init(this.onConnect, this.setCaption, console.log);
    this.defaultTheme = this.$route.query.theme && this.$route.query.theme.toString() || this.defaultTheme;
  },
  methods: {
    getDefaultTheme() {
      type ObjectKey = keyof typeof this.theme;
      const theme = this.defaultTheme as ObjectKey;
      return this.theme[theme];
    },
    setCaption(caption:string) {
      this.caption = caption;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.caption = "";
      }, this.timeout);
    },
    async onConnect() {
      SIO.sub(this.sio, this.token, (user: any) => {
        this.displayName = user.displayName;
        this.avatar = user.avatar;
        this.connected = true;
      });
    }
  }
})
