import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import HomeView from "@/views/HomeView.vue";
import DebugView from "@/views/DebugView.vue";
import ShareView from "@/views/ShareView.vue";
import TermsView from "@/views/pages/TermsView.vue";
import DocsView from "@/views/pages/DocsView.vue";
import Wave from "@/views/pages/Wave.vue"

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: "/home",
    name: "home",
    component: HomeView,
  },
  {
    path: "/",
    name: "default",
    component: HomeView,
  },
  {
    path: "/wave",
    name: "wave",
    component: Wave,
  },
  {
    path: "/debug",
    name: "debug",
    component: DebugView,
  },
  {
    path: "/terms",
    name: "terms",
    component: TermsView,
  },
  {
    path: "/share/:code",
    name: "share",
    component: ShareView,
  },
  {
    path: "/share/:code/:obs",
    name: "share",
    component: ShareView,
  },
  {
    path: "/docs",
    name: "docs",
    component: DocsView,
  },
  {
    path: "*",
    name: "default",
    component: HomeView,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
