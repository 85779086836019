
import Vue from 'vue';
import { mapGetters } from 'vuex';
import zoom from "@/util/zoom"
import ResponsiveWave from "../components/misc/ResponsiveWave.vue";
import SIO from '@/util/sio';
import defaults from '@/util/defaults';

const NOHISTORY: string[] = [];

export default Vue.extend({
  name: 'Meeting',
  components: {
    ResponsiveWave
  },

  data: () => {
    return {
      showHistory: true,
      history: NOHISTORY,
      caption: "",
      title: "",
      layout: defaults.LAYOUTS[0],
      theme: defaults.THEMES[0],
      camera: true,
      webview: true,
      sioSubUser: defaults.NOSUB,
      shareCode: "",
      layoutIndex: 0,
      themeIndex: 0,
      posting: "Start",
      timer: 0,
      tabs: "fields",
      demoCaption: "This is a demo for AIHEARU Captions",
      captionTimer: null as any,
      state: "init",
      sio: null as any,
      codeWarning: false,
      persistStorage: {} as any,
      timeout: 10,
      debug: false
    }
  },
  computed: {
    ...mapGetters([
      'inZoom',
      'version',
      'zoomToMeeting'
    ]),
  },
  mounted() {
    if (this.inBrowser) {
      this.persistStorage = sessionStorage;
    } else {
      this.persistStorage = localStorage;
    }
    this.loadPersisted();
    if (this.sioSubUser.active) {
      this.SIOSub();
    }
    if (this.inBrowser || this.inZoom) this.zoomMeetingsInit();
  },
  props: {
    inBrowser: Boolean,
  },
  watch: {
    inZoom(val) {
      // if (val) this.sendTheme();
      this.zoomMeetingsInit();
    },
    zoomToMeeting(val) {
      this.handleMessage(val.payload);
    }
  },
  methods: {
    clearHistory() {
      this.history = NOHISTORY;
      this.caption = "";
      this.persist();
    },
    removeHistory(value: string) {
      this.history = this.history.filter(item => item.trim() !== value.trim())
    },
    addHistory(value: string) {
      let item = value.trim();
      if (item.length > 0) {
        this.removeHistory(item);
        this.history.unshift(item);
      }
    },
    enableDebug() {
      this.debug = true;
      this.persist();
    },
    disableDebug() {
      this.debug = false;
      this.persist();
    },
    timeoutChanged() {
      this.applyCaptionTimeout(this.timeout)
      this.persist();
    },
    loadPersisted() {
      const retrievedModel: any = this.persistStorage.getItem("ai-caption-model") || JSON.stringify({});
      const model = JSON.parse(retrievedModel);

      // shareCode always localStorage
      this.shareCode = localStorage.getItem("shareCode") || "";
      this.setModel(model);
    },
    setModel(model: any) {
      this.theme = model.theme || this.theme;
      this.caption = (model.caption === undefined) ? this.caption : model.caption;
      this.title = (model.title === undefined) ? this.title : model.title;
      this.camera = (model.camera === undefined) ? this.camera : model.camera;
      this.webview = (model.webview === undefined) ? this.webview : model.webview;
      this.layoutIndex = model.layoutIndex || this.layoutIndex;
      this.layout = model.layout || this.layout;
      this.themeIndex = model.themeIndex || this.themeIndex;
      this.sioSubUser = model.sioSubUser || this.sioSubUser; 
      this.timeout = model.timeout || this.timeout;    
      this.debug = model.debug || this.debug;
      this.history = model.history || this.history;
    },
    getModel() {
      return {
        themeIndex: this.themeIndex,
        layoutIndex: this.layoutIndex,
        theme: this.theme,
        caption: this.caption,
        title: this.title,
        camera: this.camera,
        webview: this.webview,
        sioSubUser: this.sioSubUser,
        layout: this.layout,
        timeout: this.timeout,
        debug: this.debug,
        history: this.history
      }
    },
    persist() {
      const model = this.getModel();
      this.persistStorage.setItem("ai-caption-model", JSON.stringify(model));
      localStorage.setItem("shareCode", this.shareCode);
    },
    applyModel() {
      zoom.send(this.getModel(), "meeting");
    },
    SIOSub() {
      if (!this.sio) {
        this.sio = SIO.init(this.onSIOConnected, this.onSIOCaption, console.log);
      }
      if (this.shareCode) {
        this.shareCode = this.shareCode.split("/").slice(-1)[0];
        SIO.sub(this.sio, this.shareCode, this.onSIOSub);
        this.codeWarning = true;
      }
    },
    onSIOSub(user: any, shareCode: string) {
      console.log("🚀 ~ file: MeetingView.vue ~ line 284 ~ onSIOSub ~ user, shareCode", user, shareCode)
      if (user) {
        this.sioSubUser = user;
        this.sioSubUser.active = true;
        this.sioSubUser.code = shareCode;
        zoom.send({ sioSubUser: user }, "meeting"); // TODO
        this.shareCode = shareCode;
        this.codeWarning = false;
        this.persist();
      } else {
        this.sioSubUser = defaults.NOSUB;
        this.codeWarning = true;
        console.log("🚀 ~ file: MeetingView.vue ~ line 280 ~ onSIOSub ~ user", user)        
      }
    },
    onSIOConnected() {
      console.log("Meeting Connected to SIO");
    },
    onSIOCaption(caption: string) {
      this.caption = caption;
      this.sendCaption(this.timeout);
    },
    onSIOUnsubscribe(user: any, code: string) {
      zoom.send({ sioSubUser: defaults.NOSUB }, "meeting");
      this.sioSubUser = defaults.NOSUB;
      this.persist();
    },
    zoomMeetingsInit() {
      zoom.onMessage(this.handleMessage);
      let timer = setInterval(() => {
        zoom.send({ ping: true }, "meeting");
        if (this.state === "connected") {
          console.log("🚀 ~ file: MeetingView.vue ~ line 268 ~ timer ~ this.state", this.state);
          clearInterval(timer);
          this.applyModel();
        }
      }, 1000);
    },
    handleMessage(payload: any) {
      console.debug("🚀 ~ file: MeetingView.vue ~ line 227 ~ handleMessage ~ payload", payload)
      if(payload.ack) {
        this.state = "connected";
      }
      console.log("PAYLOAD: " + JSON.stringify(payload));
    },
    toggleCamera() {
      this.set('camera', !this.camera);
    },
    toggleWebview() {
      this.set('webview', !this.webview);
    },
    setTheme(i: number) {
      if (i !== undefined) {
        this.set('theme', defaults.THEMES[i] )
      }
    },

    layoutChange(i: number) {
      this.set('layoutIndex', i)
    },
    clearZoomOTP() {
      if (this.shareCode === "debug") {
        this.shareCode = "";
        this.enableDebug();
        return;
      }
      if (this.sioSubUser.active) {
        this.unlinkZoomOTP();
      } 
      this.shareCode = "";
      this.set('sioSubUser', defaults.NOSUB);
    },
    unlinkZoomOTP() {
      SIO.unsubscribe(this.sio, this.sioSubUser.code, this.onSIOUnsubscribe);
      this.set('caption', "");
    },
    async toggleDebug() {
      zoom.send({ command: "toggleDebug" }, "meeting");
    },
    async sendTitle() {
      this.set('title', this.title);
    },
    applyCaptionTimeout(timer: number) {
      if (this.captionTimer) {
        window.clearTimeout(this.captionTimer);
      }
      this.captionTimer = window.setTimeout(() => {
        this.caption = "";
        this.setCaption();
        console.debug("Caption Timer EXPIRED " + timer);
      }, timer * 1000);

    },
    async sendCaption(timer = 3600*24) {
      if (this.caption && this.caption.length > 0) {
        this.applyCaptionTimeout(timer);
      }
      this.setCaption(false);
    },
    useHistoryItem(item: string) {
      this.caption = item;
      this.setCaption();
    },
    setCaption(persist = true) {
      if (persist) {
        this.addHistory(this.caption);
      }
      this.set('caption', this.caption, persist);
    },
    // this function sets, sends and persists a field
    set(field: string, value: any, persist = true) {
      let model: any = {};
      model[field] = value;
      if (field === "layoutIndex") model.layout = defaults.LAYOUTS[value];
      if (field === "themeIndex") model.theme = defaults.THEMES[value];
      this.setModel(model);
      zoom.send(model, 'meeting');
      if (persist) this.persist();      
    },
    togglePosting() {
      const inZoom = this.inZoom;
      console.log("togglePosting");
      let x = 0;
      if (this.posting === "Start") {
        const words = this.demoCaption.split(' ');
        this.timer = setInterval(async () => {
          let message = words.slice(0, x + 1).join(" ");
          this.caption = message;
          this.sendCaption(this.timeout);
          x++;
          if (x > words.length) x = 0;
        }, 600);
        this.posting = "Stop"
      } else {
        clearInterval(this.timer);
        this.posting = "Start";
      }
    },
  }
});
