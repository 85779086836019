<template>
  <!-- <transition appear name="slide-fade" mode="out-in"> -->
    <v-card :class="theme.name" :key="caption" id="caption" v-if="caption && caption.trim()" shaped dark :color="theme.captionBGColor"
      class="ma-7 pa-0" :elevation="elevation">
      <h2 :style="{ color: theme.textColor }" class="ma-7 pa-7">{{ caption }}</h2>
    </v-card>
  <!-- </transition> -->
</template>
    
<script lang=" ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'CaptionCard',
  props: {
    caption: String,
    theme: Object
  },
  data: () => ({

  }),
  computed: {
    elevation() {
      if (this.theme.captionBGColor === "#00000000") {
        return "0";
      }
      return "5"
    }
  }
})
</script>

<style scoped>
#caption {
  z-index: 100;
    text-shadow: 2px 2px 5px black;
      font-size: xx-large;
}
.solid {
  background-image: linear-gradient(200deg, #1600FE 40%, #0600AE 100%)
}
.dark {
  background-image: linear-gradient(200deg, #1600FE49 40%, #0600AE49 100%)
}
.light {
  background-image: linear-gradient(200deg, #06001E10 40%, #0600AE10 100%)
}
.transparent {
  display: block;
}

/* .slide-fade-enter-active {
  transition: all .3s ease;
}

.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter,
.slide-fade-leave-to
  {
  transform: translateX(10px);
  opacity: 1;
} */
</style>