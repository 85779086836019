
import { io } from "socket.io-client";
import { Config } from "./config";

export default class SIO {
  static init( onConnect: any, onCaption: any, LOG: any) {
      const sio = io(Config().sio, {
        path: Config().sioPath,
        reconnection: true,
        extraHeaders: {
          retry: "0",
        },
      });
      LOG("🚀 ~ file: sio.ts ~ line 15 ~ SIO ~ init ~ Config().project  " + Config().project)

      sio.on('caption', (x: any) => {
        LOG('🚀 ~ file: sio.ts ~ AIHEARU STREAM socket CAPTION: ' + JSON.stringify(x));
        onCaption(x.text + (x.type === "get-inter-decode" ? " . . .": ""));

      });
      sio.on('connect', () => {
        onConnect(sio.id);
          LOG('🚀 ~ file: sio.ts ~ AIHEARU STREAM socket Connected: received SIO: ' + sio.id);
      });
      sio.on('connect_error', (res: any) => {
        try {
          LOG('🚀 ~ file: sio.ts ~ AIHEARU STREAM socket connect_error ' + JSON.parse(res.message).status + res.message + JSON.stringify(res));
        } catch (err) {
          LOG("🚀 ~ file: sio.ts ~ line 61 ~ SIO ~ sio.on ~ err " + JSON.stringify(err) + JSON.stringify(res))
        }
      });

      sio.on('disconnect', (res: any) => {
        LOG('🚀 ~ file: sio.ts ~ AIHEARU STREAM socket disconnected' + JSON.stringify(res));
      });
      return sio;
  }
  static async sub(sio:any, otp: string, onComplete: any) {
    sio.emit('subscribe', { otp: otp }, (res:any) => {
      onComplete(res.info, otp);
    } ); 
  }
  static async unsubscribe(sio:any, otp: string, onComplete: any) {
    sio.emit('unsubscribe', { otp: otp }, (res:any) => {
      onComplete(res, otp);
    } ); 
  }

}