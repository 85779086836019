
import Vue from 'vue';
import CameraLayoutBottom from '@/components/layouts/Bottom.vue';
import CameraLayoutUp from '@/components/layouts/Up.vue';
import CameraLayoutCenter from '@/components/layouts/Center.vue';
import CameraLayoutShorts from '@/components/layouts/Shorts.vue';
import CameraLayoutSide from '@/components/layouts/Side.vue';
import zoomSdk from "@zoom/appssdk"
import { mapGetters } from 'vuex';
import { io } from "socket.io-client";
import ResponsiveWave from "../components/misc/ResponsiveWave.vue";
import zoom from "@/util/zoom"
import defaults from '@/util/defaults';

export default Vue.extend({
  name: 'Camera',
  components: {
    CameraLayoutBottom,
    CameraLayoutCenter,
    CameraLayoutShorts,
    CameraLayoutUp,
    CameraLayoutSide,
    ResponsiveWave
},
  computed: {
    ...mapGetters([
      'inZoom',
      'zoomToCamera',
      'version'
    ]),
  },
  watch: {
    version(val) {
      this.LOG("version " + val);
    },
    inZoom(value, old) {
      if (value) {
        this.cameraInit();
        this.LOG("GOT ZOOM READY so initializing camera "+ value);
      }
    },
    zoomToCamera(value, old) {
      if (value) {
        this.handleZoomMessage(value.payload);
      }
    },
    state(val) {
      this.LOG("State: " + val);
    }
  },
  props: {
    inBrowser: Boolean,
  },
  data: () => {
    return {
      caption: "",
      debugMsg: "",
      title: "",
      layout: "CameraLayoutUp",
      debug: false,
      theme: {
        captionBGColor: "#00000070",
        textColor: "#FFFFFF",
        name: "light",
      },
      state: "init",
      bg: "nobg",
      showWebView: true,
      linkCode: "",
      // linked: false,
      captionSyncWithMeeting: true,
      sioSubUser: defaults.NOSUB
    }
  },
  mounted() {
    this.LOG("State: " + this.state);
    if (this.inZoom) this.cameraInit();
    // this.sio = SIO.init(this.sioConnected, this.onSIOCaption, this.LOG);
    if (this.inBrowser) this.bg = "bg";
  },
  methods: {
    onSIOCaption(caption: string) {
      if (caption !== this.caption && this.captionSyncWithMeeting) {
        zoom.send({ caption: caption }, "camera");
        this.caption = caption;
      } else {
        this.LOG("ignoring onSIOCaption since no change: " + caption);
      }
    },
    setTheme(payload:any) {
      this.theme.name = payload.theme;
      switch (payload.theme) {
        case "transparent":
          this.theme.captionBGColor = "#00000000";
          break;
        case "dark":
          this.theme.captionBGColor = "#1600FEA0";
          break;
        case "solid":
          this.theme.captionBGColor = "#1600FE";
          break;
        default:
          this.theme.captionBGColor = "#00000070";
          break;
      }   
    },
    handleZoomMessage(payload: any) {
      this.LOG("GOT ZOOM SDK call " + JSON.stringify(payload)); 
      this.state = "connected";

      if (payload.camera !== undefined) {
        if (payload.camera) {
          this.cameraDrawParticipant();
        } else {
          this.clearParticipant();
        }
      }
      if (payload.webview !== undefined) {
        if (payload.webview) {
          this.drawWebView();
        } else {
          this.clearWebView();
        }
      }
      if (payload.ping) zoom.send({ ack: true }, "camera");

      if (payload.caption !== undefined) this.caption = payload.caption;
      if (payload.theme !== undefined) this.setTheme(payload);
      if (payload.title !== undefined) this.title = payload.title;
      if (payload.layout !== undefined) this.layout = payload.layout;
      if (payload.sioSubUser !== undefined) this.sioSubUser = payload.sioSubUser;
      if (payload.command !== undefined) {
        if (payload.command === "toggleDebug") {
          this.debug = !this.debug;
          if (this.debug) this.debugMsg = "";
          this.LOG(this.version);
        }
      }   
    },
    LOG(x: string) {
      console.log(x)
      if (this.debug) {
        //TODO debug logs can be made cyclic
        if (this.debugMsg.length > 400) this.debugMsg = "";
        this.debugMsg += "] " + x + "\n";
      }
    },

    async cameraInit() {
      zoom.onMessage((payload:any) => {
        this.handleZoomMessage(payload);
      });
      this.cameraDrawParticipant();
      this.drawWebView();
    },
    
    async clearParticipant() {
      if (this.inBrowser) {
        this.bg = "nobg";
        return;
      }
      const uc = await zoomSdk.callZoomApi('getUserContext');
      await zoomSdk.callZoomApi('clearParticipant', {
        x: 0,
        y: 0,
        width: 1280,
        height: 720,
        zIndex: 10,
        participantUUID: uc.participantUUID,
      });
      this.LOG('Zoom JS SDK clearParticipant');
    }, 

    async cameraDrawParticipant() {
      if (this.inBrowser) {
        this.bg = "bg";
        return;
      }

      const uc = await zoomSdk.callZoomApi('getUserContext');
      // this.caption = "Hello! " + uc.screenName + " on board";
      await zoomSdk.callZoomApi('drawParticipant', {
        x: 0,
        y: 0,
        width: 1280,
        height: 720,
        zIndex: 10,
        participantUUID: uc.participantUUID,
      });
      this.LOG('Zoom JS SDK drawParticipant');
    }, 
    async drawWebView() {
      if (this.inBrowser) {
        this.showWebView = true;
        return;
      }

      await zoomSdk.callZoomApi('drawWebView', {
        webviewId: 'A',
        x: 0,
        y: 0,
        width: 1280,
        height: 720,
        zIndex: 20,
      });
      this.LOG('Zoom JS SDK drawWebView');
    },
    async clearWebView() {
      if (this.inBrowser) {
        this.showWebView = false;
        return;
      }
      await zoomSdk.callZoomApi('clearWebView', {
        webviewId: 'A',
        x: 0,
        y: 0,
        width: 1280,
        height: 720,
        zIndex: 20,
      });
      this.LOG('Zoom JS SDK clearWebView');
    }
  }
});
