
import Vue from 'vue';
// import mdFile from '../Changelog.md';

export default Vue.extend({
  name: 'Docs',
  data: () => {
    return {
    }
  },
});
