
import Vue from 'vue';
import CameraView from '@/views/CameraView.vue';
import MeetingView from '@/views/MeetingView.vue';

export default Vue.extend({
  name: 'Debug',
  components: {CameraView, MeetingView},
  data: () => {
    return {
      showMeetingControls: true
    }
  },
});
