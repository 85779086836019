
  import Vue from 'vue'
import CaptionCard from '@/components/cards/CaptionCard.vue'
import TitleCard from '@/components/cards/TitleCard.vue'

  export default Vue.extend({
    name: "CameraDefault",
    props: {
        title: String,
        caption: String,
        logo: {
            type: String,
            default: "https://aihearu.com/imgs/logo.png"
        },
      theme: Object
    },
    data: () => ({}),
    components: { CaptionCard, TitleCard }
})
