import zoomSdk from "@zoom/appssdk";
import store from "@/store";

export default class zoom {
  static async send(payload: any, from: string) {
    if (store.getters.inZoom) {
      const res = await zoomSdk.postMessage(payload);
    } else {
      if (from === "camera") {
        store.commit("zoomToMeeting", { payload: payload });
      } else {
        store.commit("zoomToCamera", { payload: payload });
      }
    }
  }

  static async zoomInit() {
    try {
      const configResponse = await zoomSdk.config({
        size: { width: 1280, height: 720 },
        capabilities: [
          /* Add Capabilities Here */
          "shareApp",
          "getRunningContext",
          "runRenderingContext",
          "closeRenderingContext",
          "drawParticipant",
          "clearParticipant",
          "drawImage",
          "clearImage",
          "drawWebView",
          "clearWebView",
          "postMessage",
          "sendAppInvitationToAllParticipants",
          "onMessage",
          "onMyMediaChange",
          "connect",
          "onConnect",
        ],
      });

      console.log("Zoom JS SDK configResponse", configResponse);
      store.commit("inZoom", true);
      // await zoomSdk.callZoomApi('connect');
      const meetingUUID = (await zoomSdk.callZoomApi("getMeetingUUID"))
        .meetingUUID;
      // console.log('MeetingUUID, User context ', meetingUUID);

      // console.log('LIVE TRANSCRIPT initialized');
      // await zoomSdk.onLiveTranscript((event) => {
      //     console.log('LIVE TRANSCRIPT', event);
      // });
      await zoomSdk
        .runRenderingContext({
          view: "camera",
        })
        .then((ctx) => {
          console.log("runRenderingContext returned", ctx);
        })
        .catch((e) => {
          console.log(e);
        });
      console.log("Zoom JS SDK runRenderingContext");

      const rc = await zoomSdk.getRunningContext();
      return rc.context;
    } catch (e: any) {
      console.log("Must be running in browser, as we got error:" + e.message);
      return "inBrowser";
    }
  }
  static onMessage(cb: any) {
    zoomSdk.onMessage((c) => {
      const payload = JSON.parse(c.payload.toString());
      console.log("PAYLOAD: " + JSON.stringify(payload));
      cb(payload);
    });
  }
}
