export default class defaults {
  static projectBasedOnUrl = () => {
    if (
      location.hostname.startsWith("aihearu-captions") ||
      location.hostname.startsWith("captions.aihearu.com")
    ) {
      return "aihearu-captions";
    }
    return "zoom-captions-dev";
  };

  static NOSUB = {
    active: false,
    displayName: "",
    avatar: "",
    code: "",
  };

  static LAYOUTS = [
    "CameraLayoutBottom",
    "CameraLayoutUp",
    "CameraLayoutSide",
    "CameraLayoutCenter",
    "CameraLayoutShorts"
  ];
  
  static THEMES = ["transparent", "light", "dark", "solid"];
}