
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class ResponsiveWave extends Vue {
  @Prop({ default: '#00000000' }) readonly topColor!: string
  @Prop({ default: '#00000000' }) readonly bottomColor!: string

}
