
import Vue from 'vue';
import mdFile from '../Changelog.md';
import mdFileProd from '../ChangelogProd.md';
import defaults from './util/defaults';

export default Vue.extend({
  name: 'App',
  data: () => {
    return {
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      this.getVersion();
    },
    getVersion() {
      const regex = /` [0-9.]+/g
      const found = this.changeLog().match(regex);
      this.$store.commit("version", found[0].substr(2))
    },
    changeLog() { 
      return (defaults.projectBasedOnUrl().match("aihearu-captions")) ? mdFileProd.toString() : mdFile.toString();
    },
  }
});
