<template>
  <v-card class="ma-0 pa-0" id="title" v-if="title && title.trim()" color="#00000000" elevation="0">
    <h1>{{ title }} </h1>
  </v-card>
</template>
    
<script lang=" ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'TitleCard',
  props: {
    title: String,
  },
  data: () => ({

  }),
});
</script>

<style scoped>
#title {
  color: white;
  width: 100%;
  text-align: center;
  text-shadow: 2px 2px 5px black;
  z-index: 1000;
  font-size: xx-large;
}
</style>