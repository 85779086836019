
import Vue from 'vue';
import ResponsiveWave from "@/components/misc/ResponsiveWave.vue"

export default Vue.extend({
  name: 'Wave',
  components: {  ResponsiveWave },

  data: () => {
    return {
    }
  },
});
