const config = {
  "zoom-caption-dev": {
    dev: true,
    sio: "https://api.aihearu.com/",
    sioPath: "/dev/socket.io/",
    project: "zoom-caption-dev",
  },
  "aihearu-captions": {
    dev: false,
    sio: "https://api.aihearu.com/",
    sioPath: "/socket.io/",
    project: "aihearu-captions",
  },
};

export function Config() {
    if (
      location.hostname === "captions.aihearu.com" ||
      location.search === "?prod"
    ) {
      return config["aihearu-captions"];
  }
  return config["zoom-caption-dev"];
}
