import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    inZoom: false,
    zoomToCamera: { payload: {} },
    zoomToMeeting: { payload: {} },
    version: "0.0.0"
  },
  getters: {
    inZoom: state => {
      return state.inZoom;
    },
    zoomToCamera: state => {
      return state.zoomToCamera;
    },
    zoomToMeeting: state => {
      return state.zoomToMeeting;
    },
    version: state => {
      return state.version;
    }

  },
  mutations: {
    inZoom: (state, value) => {
      state.inZoom = value;
    },
    zoomToCamera: (state, value) => {
      state.zoomToCamera = value;
    },
    zoomToMeeting: (state, value) => {
      state.zoomToMeeting = value;
    },
    version: (state, value) => {
      state.version = value;
    }
  },
  actions: {
  },
  modules: {
  }
})
