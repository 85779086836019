
import Vue from 'vue';
import CameraView from '@/views/CameraView.vue';
import MeetingView from '@/views/MeetingView.vue';
import zoom from '@/util/zoom';

export default Vue.extend({
  name: 'Docs',
  components: { CameraView, MeetingView },
  data: () => {
    return {
      inMeeting: false,
      inCamera: false
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      const context = await zoom.zoomInit();
      if (context === "inMeeting") this.inMeeting = true;
      if (context === "inCamera") this.inCamera = true;
    }
  }
});
